<!-- 社会公益 -->

<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-xxl-12 col-xl-12">
        <div v-html="result" class="world mt-80-mb-140"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getWorld } from '../../../server/intoHuadu'
import { ref, onMounted } from 'vue'
const result = ref(null)
onMounted(async () => {
  const r = await getWorld()
  result.value = r.content
})
</script>
<style lang="stylus" scoped>
.world
  overflow hidden
</style>
